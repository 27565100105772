export default function CaretRightCircleIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#0A9373" />
      <path
        d="M7.31363 5.04833C7.68 4.65986 8.30905 4.66098 8.6739 5.05169L12.9483 9.3261C13.3401 9.692 13.3401 10.3236 12.9483 10.6895L8.67389 14.9639C8.30904 15.3546 7.68 15.3558 7.31363 14.9673C6.92527 14.601 6.92629 13.9722 7.3167 13.6073L10.9056 9.99718L7.31699 6.40858C6.92629 6.04373 6.92517 5.41469 7.31363 5.04833Z"
        fill="#F9F3ED"
        stroke="#F9F3ED"
        strokeWidth="0.5"
      />
    </svg>
  );
}
